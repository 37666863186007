import React from 'react';
import { AppBar, Toolbar, Typography, Button, Badge } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';

export function Header() {
  const navigate = useNavigate();
  const { cart } = useCart();

  const itemCount = cart.reduce((count, item) => count + item.quantity, 0);

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black', mb: 3 }}>
      <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1, cursor: 'pointer' }}
          onClick={() => navigate('/')}
        >
          Red Austral Gomas
        </Typography>
        
        <Button 
          sx={{ color: '#fed700' }}
          onClick={() => navigate('/cart')}
          startIcon={
            <Badge badgeContent={itemCount} color="primary">
              <ShoppingCart />
            </Badge>
          }
        >
          Carrito
        </Button>
      </Toolbar>
    </AppBar>
  );
}