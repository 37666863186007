import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  Divider,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useCart } from '../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { api } from '../Services/api';

export function Checkout() {
  const { cart, clearCart } = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('CREDIT_CARD');
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    dni: '',
    phone: ''
  });

  const [cardInfo, setCardInfo] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: ''
  });

  const total = cart.reduce((sum, item) => sum + (item.price * item.quantity), 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const paymentData = {
        amount: total,
        paymentMethod: 'CREDIT_CARD', // Agregamos el método de pago
        customerInfo: {
          ...customerInfo,
          cardInfo: {
            ...cardInfo,
            expiry: cardInfo.expiry.replace('/', ''), // Formato MMYY
          }
        },
        orderDetails: cart.map(item => ({
          productId: item.id,
          quantity: item.quantity,
          price: item.price,
          name: item.name
        }))
      };
  
      console.log('Enviando datos de pago:', {
        ...paymentData,
        customerInfo: {
          ...paymentData.customerInfo,
          cardInfo: {
            ...paymentData.customerInfo.cardInfo,
            number: 'XXXX' // Ocultamos el número de tarjeta en los logs
          }
        }
      });

      const response = await api.initiatePayment(paymentData);

      if (response.success) {
        clearCart();
        if (response.data?.paymentUrl) {
          window.location.href = response.data.paymentUrl;
        } else if (response.data?.status === 'PENDING') {
          navigate(`/payment-pending/${response.data.transactionId}`);
        } else {
          navigate('/payment-success');
        }
      }
    } catch (err) {
      console.error('Error en el pago:', err);
      setError(err.response?.data?.error || err.message || 'Error al procesar el pago');
    } finally {
      setLoading(false);
    }
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    value = value.replace(/(\d{4})/g, '$1 ').trim();
    setCardInfo({ ...cardInfo, number: value });
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (value.length >= 2) {
      value = value.slice(0, 2) + '/' + value.slice(2, 4);
    }
    setCardInfo({ ...cardInfo, expiry: value });
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" sx={{ mb: 4 }}>
        Checkout
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Información Personal
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre completo"
                    value={customerInfo.name}
                    onChange={(e) => setCustomerInfo({
                      ...customerInfo,
                      name: e.target.value
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="email"
                    label="Email"
                    value={customerInfo.email}
                    onChange={(e) => setCustomerInfo({
                      ...customerInfo,
                      email: e.target.value
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="DNI"
                    value={customerInfo.dni}
                    onChange={(e) => setCustomerInfo({
                      ...customerInfo,
                      dni: e.target.value
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Teléfono"
                    value={customerInfo.phone}
                    onChange={(e) => setCustomerInfo({
                      ...customerInfo,
                      phone: e.target.value
                    })}
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Método de Pago</InputLabel>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                label="Método de Pago"
              >
                <MenuItem value="CREDIT_CARD">Tarjeta de Crédito</MenuItem>
                <MenuItem value="DEBIT_CARD">Tarjeta de Débito</MenuItem>
              </Select>
            </FormControl>
          </Grid>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Información de Pago
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Número de tarjeta"
                    value={cardInfo.number}
                    onChange={handleCardNumberChange}
                    inputProps={{ maxLength: 19 }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre en la tarjeta"
                    value={cardInfo.name}
                    onChange={(e) => setCardInfo({
                      ...cardInfo,
                      name: e.target.value.toUpperCase()
                    })}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Vencimiento (MM/YY)"
                    value={cardInfo.expiry}
                    onChange={handleExpiryChange}
                    inputProps={{ maxLength: 5 }}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="CVC"
                    value={cardInfo.cvc}
                    onChange={(e) => setCardInfo({
                      ...cardInfo,
                      cvc: e.target.value.replace(/\D/g, '').slice(0, 4)
                    })}
                    inputProps={{ maxLength: 4 }}
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Resumen del Pedido
              </Typography>
              
              {cart.map((item) => (
                <Box key={item.id} sx={{ my: 2 }}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8}>
                      <Typography>
                        {item.name} x {item.quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ textAlign: 'right' }}>
                      <Typography>
                        ${(item.price * item.quantity).toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Divider sx={{ my: 2 }} />

              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6">Total</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    ${total.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                disabled={loading}
              >
                {loading ? 'Procesando...' : 'Pagar'}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}