import React from 'react';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Cart } from './pages/Cart';
import { Checkout } from './pages/Checkout';
import { PaymentCallback } from './pages/PaymentCallback';

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/payment-callback" element={<PaymentCallback />} />
    </RouterRoutes>
  );
};