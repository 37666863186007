import axios from 'axios';

const API_BASE_URL = 'http://142.93.59.1/api';  // Actualizar a la IP del servidor

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
    withCredentials: false  // Cambiado a false por ahora para pruebas
});

//PAGOS
export const api = {
    async initiatePayment(paymentData) {
        try {
            console.log('Iniciando solicitud de pago con datos:', paymentData);
            
            // Estructurar los datos según espera el backend
            const paymentRequest = {
                amount: paymentData.amount,
                customerInfo: {
                    name: paymentData.customerInfo.name,
                    email: paymentData.customerInfo.email,
                    dni: paymentData.customerInfo.dni,
                    cardInfo: paymentData.customerInfo.cardInfo
                },
                paymentMethod: paymentData.paymentMethod,
                orderDetails: paymentData.orderDetails
            };

            const response = await apiClient.post('/payments/create', paymentRequest);
            console.log('Respuesta del servidor:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error en initiatePayment:', error.response?.data || error);
            throw error;
        }
    },



  

  async verifyPayment(verificationData) {
    try {
        console.log('Verificando pago:', verificationData);
        const response = await apiClient.post('/payments/verify', verificationData);
        console.log('Respuesta de verificación:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error en verificación:', error.response?.data || error.message);
        throw error;
    }
}


};



