import React from 'react';
import { 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Button,
  Container,
  Box 
} from '@mui/material';
import { useCart } from '../context/CartContext';

const MOCK_PRODUCTS = [
  {
    id: 1,
    name: 'Neumático P ZERO™',
    description: 'Neumático de alto rendimiento para vehículos deportivos',
    price: 150000,
    image: 'https://redaustralgomas.com/wp-content/uploads/2020/09/p-zero-img-1.jpg'
  },
  {
    id: 2,
    name: 'SCORPION™ ATR',
    description: 'Neumático todo terreno para SUVs y camionetas',
    price: 180000,
    image: 'https://redaustralgomas.com/wp-content/uploads/2020/09/scorpion-atr-img-1.jpg'
  }
];

export function Home() {
  const { addToCart } = useCart();
  const products = MOCK_PRODUCTS;

  return (
    <Container>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Neumáticos Disponibles
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={product.image}
                alt={product.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  {product.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {product.description}
                </Typography>
                <Typography variant="h6" color="primary" gutterBottom>
                  ${product.price.toLocaleString()}
                </Typography>
                <Button 
                  variant="contained" 
                  fullWidth
                  onClick={() => addToCart(product)}
                >
                  Agregar al carrito
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}