import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Alert } from '@mui/material';
import { api } from '../Services/api';

export function PaymentCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState(null);

  const verifyPayment = useCallback(async () => {
    try {
      const orderId = searchParams.get('order_id');
      const status = searchParams.get('status');
      const transactionId = searchParams.get('transaction_id');

      const response = await api.verifyPayment({
        orderId,
        status,
        transactionId
      });

      if (response.success) {
        setStatus('success');
        setTimeout(() => {
          navigate('/payment-success');
        }, 3000);
      } else {
        setStatus('error');
        setError(response.message);
      }
    } catch (error) {
      setStatus('error');
      setError(error.message);
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    verifyPayment();
  }, [verifyPayment]);

  return (
    <Container maxWidth="sm" sx={{ mt: 4, textAlign: 'center' }}>
      {status === 'processing' && (
        <>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Verificando el pago...
          </Typography>
        </>
      )}

      {status === 'success' && (
        <Alert severity="success">
          ¡Pago realizado con éxito! Serás redirigido en unos segundos...
        </Alert>
      )}

      {status === 'error' && (
        <Alert severity="error">
          Error en el pago: {error}
        </Alert>
      )}
    </Container>
  );
}