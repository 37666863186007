import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { Header } from './components/Header/Header';
import {Routes} from './Routes';
import { Box } from '@mui/material';

function App() {
  return (
    <BrowserRouter>
      <CartProvider>
        <Box sx={{ minHeight: '100vh' }}>
          <Header />
          <Routes />
        </Box>
      </CartProvider>
    </BrowserRouter>
  );
}

export default App;